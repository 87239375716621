// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[aria-hidden=true]{display:none}.bgsu_toggle_3-X8V .toggle-control{background:transparent;border:0;font:inherit;line-height:1.5;margin:0;padding:0;pointer-events:none;text-align:left}.bgsu_toggle_3-X8V .toggle-control[aria-controls]{cursor:pointer;pointer-events:auto}", ""]);
// Exports
exports.locals = {
	"toggle": "bgsu_toggle_3-X8V"
};
module.exports = exports;
