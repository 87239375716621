var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (config, css) {
      pug_mixins["link"] = pug_interp = function(item){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (item.href && item.href !== '#') {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", item.href, true, true)+pug.attr("title", item.title, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.text || item.href) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else
if (item.menu) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"toggle-control\""+pug.attr("aria-label", item.title, true, true)+pug.attr("data-toggle", item.toggle, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.text) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003Cul" + (pug.attr("id", item.toggle, true, true)) + "\u003E";
// iterate item.menu
;(function(){
  var $$obj = item.menu;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var subitem = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["link"](subitem);
pug_html = pug_html + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var subitem = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["link"](subitem);
pug_html = pug_html + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E";
}
else {
pug_html = pug_html + (pug.escape(null == (pug_interp = item.text) ? "" : pug_interp));
}
};
pug_mixins["form"] = pug_interp = function(form){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cform" + (pug.attr("action", form.action, true, true)+pug.attr("method", form.method, true, true)) + "\u003E\u003Cdiv\u003E";
if (form.hidden) {
// iterate form.hidden
;(function(){
  var $$obj = form.hidden;
  if ('number' == typeof $$obj.length) {
      for (var name = 0, $$l = $$obj.length; name < $$l; name++) {
        var value = $$obj[name];
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\""+pug.attr("name", name, true, true)+pug.attr("value", value, true, true)) + "\u003E";
      }
  } else {
    var $$l = 0;
    for (var name in $$obj) {
      $$l++;
      var value = $$obj[name];
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\""+pug.attr("name", name, true, true)+pug.attr("value", value, true, true)) + "\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003Cinput" + (" type=\"text\""+pug.attr("name", form.name || "search", true, true)+pug.attr("value", form.value || "", true, true)+pug.attr("aria-label", form.text || "Search Site", true, true)+pug.attr("placeholder", form.text || "Search Site", true, true)) + "\u003E\u003Cbutton type=\"submit\"\u003E\u003Cspan" + (pug.attr("aria-label", form.button || "Search", true, true)) + "\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
if (form.menu) {
pug_html = pug_html + "\u003Cul\u003E";
// iterate form.menu
;(function(){
  var $$obj = form.menu;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var item = $$obj[index];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["link"](item);
pug_html = pug_html + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var item = $$obj[index];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["link"](item);
pug_html = pug_html + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E";
}
pug_html = pug_html + "\u003C\u002Fform\u003E";
};
pug_html = pug_html + "\u003Cheader" + (pug.attr("class", pug.classes([css.header], [true]), false, true)) + "\u003E";
if ((config.main && config.main.id && config.main.top)) {
pug_html = pug_html + "\u003Ca" + (pug.attr("class", pug.classes([css.skip], [true]), false, true)+pug.attr("href", "#" + config.main.id, true, true)+pug.attr("id", config.main.top, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = 'Skip to main content') ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.top], [true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.container], [true]), false, true)) + "\u003E\u003Ca" + (pug.attr("class", pug.classes([css.logo], [true]), false, true)+" href=\"https:\u002F\u002Fwww.bgsu.edu\u002F\"") + "\u003E\u003Cimg" + (pug.attr("src", require("../../svg/bgsu.svg"), true, true)+" alt=\"BGSU\" width=\"60\" height=\"18\"") + "\u003E\u003C\u002Fa\u003E";
if (config.unit && config.unit.text) {
pug_html = pug_html + "\u003Ca" + (pug.attr("class", pug.classes([css.unit], [true]), false, true)+pug.attr("href", config.unit.href, true, true)) + "\u003E";
if (config.unit.thin) {
pug_html = pug_html + "\u003Cspan" + (pug.attr("class", pug.classes([css.thin], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = config.unit.thin + " ") ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + (pug.escape(null == (pug_interp = config.unit.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if ((config.site || config.menu || config.user || config.form)) {
pug_html = pug_html + "\u003Cnav" + (pug.attr("class", pug.classes([css.nav], [true]), false, true)+" aria-label=\"Site navigation\"") + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.container], [true]), false, true)) + "\u003E";
if (config.site) {
if ((config.site.heading)) {
pug_html = pug_html + "\u003Ch1" + (pug.attr("class", pug.classes([css.site], [true]), false, true)) + "\u003E";
pug_mixins["link"](config.site);
pug_html = pug_html + "\u003C\u002Fh1\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.site], [true]), false, true)) + "\u003E";
pug_mixins["link"](config.site);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
}
if (config.menu || config.form) {
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes([css.toggle + " toggle-control"], [true]), false, true)+" aria-label=\"Toggle site navigation\""+pug.attr("data-toggle", css.menu, true, true)) + "\u003E☰\u003C\u002Fbutton\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.menu], [true]), false, true)+pug.attr("id", css.menu, true, true)) + "\u003E";
if (config.menu) {
pug_html = pug_html + "\u003Cul\u003E";
// iterate config.menu
;(function(){
  var $$obj = config.menu;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var item = $$obj[index];
item.toggle = css.menu + "_" + index
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes([item.class], [true]), false, true)) + "\u003E";
pug_mixins["link"](item);
pug_html = pug_html + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var item = $$obj[index];
item.toggle = css.menu + "_" + index
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes([item.class], [true]), false, true)) + "\u003E";
pug_mixins["link"](item);
pug_html = pug_html + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E";
}
if (config.user) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.user], [true]), false, true)) + "\u003E";
if (config.user.text) {
pug_html = pug_html + "\u003Cdiv\u003E" + (pug.escape(null == (pug_interp = config.user.text) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
if (config.user.menu) {
pug_html = pug_html + "\u003Cul\u003E";
// iterate config.user.menu
;(function(){
  var $$obj = config.user.menu;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var item = $$obj[index];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["link"](item);
pug_html = pug_html + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var item = $$obj[index];
pug_html = pug_html + "\u003Cli\u003E";
pug_mixins["link"](item);
pug_html = pug_html + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
if (config.form) {
pug_mixins["form"](config.form);
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fnav\u003E";
}
if (config.note) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.note], [true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.container], [true]), false, true)) + "\u003E" + (null == (pug_interp = config.note) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fheader\u003E";
    }.call(this, "config" in locals_for_with ?
        locals_for_with.config :
        typeof config !== 'undefined' ? config : undefined, "css" in locals_for_with ?
        locals_for_with.css :
        typeof css !== 'undefined' ? css : undefined));
    ;;return pug_html;};
module.exports = template;