var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (config, css) {
      pug_mixins["link"] = pug_interp = function(item){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (item.heading) {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = item.heading) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003Cbr\u003E";
}
if (item.href) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", item.href, true, true)+pug.attr("title", item.title, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.text || item.href) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + (pug.escape(null == (pug_interp = item.text) ? "" : pug_interp));
}
};
pug_html = pug_html + "\u003Cfooter" + (pug.attr("class", pug.classes([css.footer], [true]), false, true)) + "\u003E";
if (config.gray && config.gray.text) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.gray], [true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.container], [true]), false, true)) + "\u003E" + (null == (pug_interp = config.gray.text) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.container], [true]), false, true)) + "\u003E\u003Cdiv\u003E\u003Cp\u003E\u003Cimg" + (pug.attr("src", require("../../svg/bgsu.svg"), true, true)+" alt=\"BGSU\" width=\"192\" height=\"58\"") + "\u003E\u003C\u002Fp\u003E\u003Cp\u003EBowling Green State University\u003Cbr\u003E\nBowling Green, Ohio\u003Cbr\u003E\n43403-0001\u003Cbr\u003E\n419-372-2531\u003C\u002Fp\u003E";
if (config.help) {
pug_html = pug_html + "\u003Cp\u003E";
pug_mixins["link"](config.help);
pug_html = pug_html + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Cp" + (pug.attr("class", pug.classes([css.report], [true]), false, true)) + "\u003E";
pug_mixins["link"]({
                heading: "Accessibility",
                href: "https://www.bgsu.edu/accessibility.html",
                text: "Report an Issue"
            });
pug_html = pug_html + "\u003C\u002Fp\u003E\u003Cp" + (pug.attr("class", pug.classes([css.small], [true]), false, true)) + "\u003EBowling Green State University (BGSU) has built their website\naround the Standards of the World Wide Web Consortium's (W3C)\nHyper Text Markup Language (HTML) 5 and Cascading Style Sheets\n(CSS). The website was built in compliance with the\naccessibility standards established by section 508 of the\nRehabilitation Act and detailed in section 1194.22 of the Code\nof Federal Regulations, \"Web-based intranet and internet\ninformation and applications\"\n(";
pug_mixins["link"]({href: "https://www.section508.gov/"});
pug_html = pug_html + ").\u003C\u002Fp\u003E\u003Cp" + (pug.attr("class", pug.classes([css.small], [true]), false, true)) + "\u003EBGSU utilizes many web masters across campus to maintain all of\nits web properties. Keeping the BGSU website in compliance with\nsection 508 is a joint effort between Accessibility Services,\nMarketing and Communications and Information Technology\nServices. If any page is discovered to be inaccessible, please\nreport it using the above link and we will ensure that the\nissue is addressed.\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Ffooter\u003E";
    }.call(this, "config" in locals_for_with ?
        locals_for_with.config :
        typeof config !== 'undefined' ? config : undefined, "css" in locals_for_with ?
        locals_for_with.css :
        typeof css !== 'undefined' ? css : undefined));
    ;;return pug_html;};
module.exports = template;